<template>
  <div class="container">
    <div class="help-search">
      <div class="content">
        <h4>帮助中心</h4>
        <div class="search">
          <img src="../assets/img/search-icon.png" alt="" />
          <input
            type="text"
            v-model="search_text"
            placeholder="请输入您的问题，寻找解决方案"
            autocomplete="off"
            @keyup.enter="searchs"
          />
          <button type="button" class="search-btn" @click="searchs">搜索</button>
        </div>
      </div>
    </div>
    <div class="question-section">
      <div class="content">
        <div
          class="question-item"
          v-for="(item, index) in data"
          :key="index"
          @click.stop="showText(index)"
        >
          <!-- 问题 -->
          <div class="item-title">
            <h4>{{ item.title }}</h4>
            <img
              src="../assets/img/help-next0.png"
              alt=""
              :style="currentIndex == index ? 'transform:rotate(180deg)' : ''"
            />
          </div>
          <div class="item-content" v-show="currentIndex == index">
            <div
              class="help-text"
              v-html="item.answer.replace(/(\r\n)|(\n)/g, '<br>')"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { help } from "@network/help";
export default {
  name: "Help",
  data() {
    return {
      search_text: "",
      currentIndex: -1,
      data: [],
      user: {},
    };
  },

  created() {
    this.helps();
  },

  components: {},

  methods: {
    searchs() {
      if (this.search_text.trim() == "") {
        window.location.reload();
        return false;
      } else {
        this.helps();
      }
    },
    showText(index) {
      this.currentIndex = index;
    },
    helps() {
      help(this.search_text).then((res) => {
        console.log(res);
        if (res.code == 403) {
          this.$storage.remove("token");
          this.$message.error({
            message:"请重新登录",
            offset: 300,
          });
          this.$router.replace("/home");
          return false;
        }
        this.data = res.data;
      });
    },
  },

  computed: {},
};
</script>
<style lang='less' scoped>
@import "../assets/css/Help.less";
</style>