import {request} from './request'

export function help(title){
    return request({
        method:"get",
        url:"/website/help",
        params:{
            title
        }
    })
}